<template>
  <div>
    <div v-for="(item, index) in items" :key="index">
      <ItemCard
        :item="item"
        :actions="actions"
        :titleAction="titleAction"
        @actionTrigger="onActionTrigger($event, index)"
        :type="type"
      />
    </div>
    <div class="text-center mt-4">
      <v-pagination
        v-model="page"
        :length="Math.ceil(totalItems / TABLE_NUM_ITEMS)"
        @input="next"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import ItemCard from "@/components/itemCard";

import { TABLE_NUM_ITEMS } from "../constants";

export default {
  props: ["items", "actions", "titleAction", "type", "totalItems"],
  components: { ItemCard },
  data() {
    return {
      page: 1,
      TABLE_NUM_ITEMS,
    };
  },
  computed: {},
  methods: {
    onActionTrigger(event, index) {
      this.$emit("actionTrigger", { event, index });
    },
    next() {
      this.$emit("paginationChange", { event: this.page - 1 });
    },
  },
};
</script>

<style></style>
