<template>
  <v-hover v-slot:default="{ hover }">
    <v-card tile outlined class="pa-2" :elevation="hover ? 2 : 0">
      <v-row v-if="type == 'ingredient' && item.type === 'SIMPLE'">
        <v-col cols="12" sm="6" md="3" class="text-center">
          <div
            class="body-1 font-weight-bold primary--text link"
            @click="onActionClick(titleAction.type, item)"
            >{{ item.name }}</div
          >
        </v-col>
        <v-col cols="12" sm="6" md="3" class="text-center">
          <div class="body-1" v-if="item.allergenId">{{ item.allergenId }}</div>
          <div class="body-1" v-else>-</div>
        </v-col>
        <v-col cols="12" sm="6" md="2" class="text-center">
          <div class="body-1">{{ item.vegan ? "Vegana" : "Flexible" }}</div>
        </v-col>
        <v-col cols="12" sm="6" md="2" class="text-center">
          <div class="body-1">{{
            item.include_in_shopping_list ? "Incluir" : "No incluir"
          }}</div>
        </v-col>
        <v-col cols="12" sm="6" md="2" class="text-right">
          <v-icon
            v-for="(action, index) in actions"
            :key="index"
            class="mr-2 p-2"
            color="gray"
            @click="onActionClick(action.type, item)"
          >
            {{ action.icon }}
          </v-icon>
        </v-col>
      </v-row>

      <v-row v-if="type == 'ingredient' && item.type === 'COMPOUND'">
        <v-col cols="12" sm="6" md="11">
          <div
            class="body-1 font-weight-bold primary--text link"
            @click="onActionClick(titleAction.type, item)"
            >{{ item.name }}</div
          >
        </v-col>
        <v-col cols="12" sm="6" md="1" class="text-right">
          <v-icon
            v-for="(action, index) in actions"
            :key="index"
            class="mr-2 p-2"
            color="gray"
            @click="onActionClick(action.type, item)"
          >
            {{ action.icon }}
          </v-icon>
        </v-col>
      </v-row>

      <v-row v-if="type == 'recipe'">
        <v-col cols="12" sm="6" md="4" class="text-center">
          <div
            class="body-1 font-weight-bold primary--text link"
            @click="onActionClick(titleAction.type, item)"
            >{{ item.title }}</div
          >
        </v-col>
        <v-col cols="12" sm="6" md="2" class="text-center">
          <div class="body-1" v-if="item.authorId">{{ item.authorId }}</div>
          <div class="body-1" v-else>-</div>
        </v-col>
        <v-col cols="12" sm="6" md="1" class="text-center">
          <div class="body-1" v-if="item.vegan">Vegana</div>
          <div class="body-1" v-else>Flexible</div>
        </v-col>
        <v-col cols="12" sm="6" md="2" class="text-center">
          <div class="body-1"
            ><span v-if="item.is_breakfast">Desayuno, </span
            ><span v-if="item.is_lunch">Comida, </span
            ><span v-if="item.is_snack">Merienda, </span>
            <span v-if="item.is_dinner">Cena</span></div
          >
        </v-col>
        <v-col cols="12" sm="6" md="1" class="text-center">
          <v-chip small v-if="item.published" color="success" class="body-1"
            >Publicada</v-chip
          >
          <v-chip small v-else>Sin publicar</v-chip>
        </v-col>
        <v-col cols="12" sm="6" md="1" class="text-center">
          <div class="body-1">{{ item.created }}</div>
        </v-col>
        <v-col cols="12" sm="6" md="1" class="text-right">
          <v-icon
            v-for="(action, index) in actions"
            :key="index"
            class="mr-2 p-2"
            color="gray"
            @click="onActionClick(action.type, item)"
          >
            {{ action.icon }}
          </v-icon>
        </v-col>
      </v-row>

      <v-row v-if="type == 'menu'">
        <v-col cols="12" sm="6" md="10">
          <div
            class="body-1 font-weight-bold primary--text link"
            @click="onActionClick(titleAction.type, item)"
            >Semana {{ item.week_monday }} al {{ item.week_sunday }}</div
          >
        </v-col>

        <v-col cols="12" sm="6" md="2" class="text-right">
          <v-icon
            v-for="(action, index) in actions"
            :key="index"
            class="mr-2 p-2"
            color="gray"
            @click="onActionClick(action.type, item)"
          >
            {{ action.icon }}
          </v-icon>
        </v-col>
      </v-row>

      <v-row v-if="type == 'user'">
        <v-col cols="12" sm="6" md="3" class="text-center">
          <div class="body-1 font-weight-bold primary--text"
            >{{ item.first_name }} {{ item.last_name }}</div
          >
        </v-col>
        <v-col cols="12" sm="6" md="1" class="text-center">
          <div class="body-1">{{ item.type }}</div>
        </v-col>
        <v-col cols="12" sm="6" md="1" class="text-center">
          <div class="body-1">{{ item.vegan ? "Vegana" : "Flexible" }}</div>
        </v-col>
        <v-col cols="12" sm="6" md="2" class="text-center">
          <div class="body-1">{{ item.email }}</div>
        </v-col>

        <v-col cols="12" sm="6" md="1" class="text-center">
          <v-chip
            small
            v-if="item.payment_provider === 'paypal' && item.paypal_active"
            color="success"
            class="body-1"
            >PayPal</v-chip
          >
          <v-chip
            small
            v-if="item.payment_provider === 'paypal' && !item.paypal_active"
            color="error"
            class="body-1"
            >PayPal</v-chip
          >
          <v-chip small v-if="item.payment_provider === 'stripe'" class="body-1"
            >Stripe</v-chip
          >
        </v-col>
        <v-col cols="12" sm="6" md="1" class="text-center">
          <div class="body-1">{{ item.created | dateFilter }}</div>
        </v-col>
        <v-col cols="12" sm="6" md="1" class="text-center">
          <div class="body-1">{{ item.stripe_subscription_id }}</div>
        </v-col>
        <v-col cols="12" sm="6" md="2" class="text-right">
          <v-icon
            v-for="(action, index) in actions"
            :key="index"
            class="mr-2 p-2"
            color="gray"
            @click="onActionClick(action.type, item)"
          >
            {{ action.icon }}
          </v-icon>
        </v-col>
      </v-row>
    </v-card>
  </v-hover>
</template>

<script>
import * as moment from "moment";

export default {
  props: ["item", "actions", "titleAction", "type"],
  filters: {
    dateFilter(date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },
  methods: {
    onActionClick(action, item) {
      const id = this.type === "menu" ? item.week_id : item.id;
      const vegan = this.type === "user" ? item.vegan : null;
      this.$emit("actionTrigger", { action, id, vegan });
    },
  },
};
</script>

<style>
.link {
  cursor: pointer;
}
</style>
