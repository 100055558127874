var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.items, function(item, index) {
        return _c(
          "div",
          { key: index },
          [
            _c("ItemCard", {
              attrs: {
                item: item,
                actions: _vm.actions,
                titleAction: _vm.titleAction,
                type: _vm.type
              },
              on: {
                actionTrigger: function($event) {
                  return _vm.onActionTrigger($event, index)
                }
              }
            })
          ],
          1
        )
      }),
      _c(
        "div",
        { staticClass: "text-center mt-4" },
        [
          _c("v-pagination", {
            attrs: { length: Math.ceil(_vm.totalItems / _vm.TABLE_NUM_ITEMS) },
            on: { input: _vm.next },
            model: {
              value: _vm.page,
              callback: function($$v) {
                _vm.page = $$v
              },
              expression: "page"
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }