var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hover = ref.hover
          return [
            _c(
              "v-card",
              {
                staticClass: "pa-2",
                attrs: { tile: "", outlined: "", elevation: hover ? 2 : 0 }
              },
              [
                _vm.type == "ingredient" && _vm.item.type === "SIMPLE"
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center",
                            attrs: { cols: "12", sm: "6", md: "3" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "body-1 font-weight-bold primary--text link",
                                on: {
                                  click: function($event) {
                                    return _vm.onActionClick(
                                      _vm.titleAction.type,
                                      _vm.item
                                    )
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.item.name))]
                            )
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center",
                            attrs: { cols: "12", sm: "6", md: "3" }
                          },
                          [
                            _vm.item.allergenId
                              ? _c("div", { staticClass: "body-1" }, [
                                  _vm._v(_vm._s(_vm.item.allergenId))
                                ])
                              : _c("div", { staticClass: "body-1" }, [
                                  _vm._v("-")
                                ])
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center",
                            attrs: { cols: "12", sm: "6", md: "2" }
                          },
                          [
                            _c("div", { staticClass: "body-1" }, [
                              _vm._v(
                                _vm._s(_vm.item.vegan ? "Vegana" : "Flexible")
                              )
                            ])
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center",
                            attrs: { cols: "12", sm: "6", md: "2" }
                          },
                          [
                            _c("div", { staticClass: "body-1" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.item.include_in_shopping_list
                                    ? "Incluir"
                                    : "No incluir"
                                )
                              )
                            ])
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-right",
                            attrs: { cols: "12", sm: "6", md: "2" }
                          },
                          _vm._l(_vm.actions, function(action, index) {
                            return _c(
                              "v-icon",
                              {
                                key: index,
                                staticClass: "mr-2 p-2",
                                attrs: { color: "gray" },
                                on: {
                                  click: function($event) {
                                    return _vm.onActionClick(
                                      action.type,
                                      _vm.item
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(action.icon) +
                                    "\n        "
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.type == "ingredient" && _vm.item.type === "COMPOUND"
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6", md: "11" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "body-1 font-weight-bold primary--text link",
                                on: {
                                  click: function($event) {
                                    return _vm.onActionClick(
                                      _vm.titleAction.type,
                                      _vm.item
                                    )
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.item.name))]
                            )
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-right",
                            attrs: { cols: "12", sm: "6", md: "1" }
                          },
                          _vm._l(_vm.actions, function(action, index) {
                            return _c(
                              "v-icon",
                              {
                                key: index,
                                staticClass: "mr-2 p-2",
                                attrs: { color: "gray" },
                                on: {
                                  click: function($event) {
                                    return _vm.onActionClick(
                                      action.type,
                                      _vm.item
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(action.icon) +
                                    "\n        "
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.type == "recipe"
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center",
                            attrs: { cols: "12", sm: "6", md: "4" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "body-1 font-weight-bold primary--text link",
                                on: {
                                  click: function($event) {
                                    return _vm.onActionClick(
                                      _vm.titleAction.type,
                                      _vm.item
                                    )
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.item.title))]
                            )
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center",
                            attrs: { cols: "12", sm: "6", md: "2" }
                          },
                          [
                            _vm.item.authorId
                              ? _c("div", { staticClass: "body-1" }, [
                                  _vm._v(_vm._s(_vm.item.authorId))
                                ])
                              : _c("div", { staticClass: "body-1" }, [
                                  _vm._v("-")
                                ])
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center",
                            attrs: { cols: "12", sm: "6", md: "1" }
                          },
                          [
                            _vm.item.vegan
                              ? _c("div", { staticClass: "body-1" }, [
                                  _vm._v("Vegana")
                                ])
                              : _c("div", { staticClass: "body-1" }, [
                                  _vm._v("Flexible")
                                ])
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center",
                            attrs: { cols: "12", sm: "6", md: "2" }
                          },
                          [
                            _c("div", { staticClass: "body-1" }, [
                              _vm.item.is_breakfast
                                ? _c("span", [_vm._v("Desayuno, ")])
                                : _vm._e(),
                              _vm.item.is_lunch
                                ? _c("span", [_vm._v("Comida, ")])
                                : _vm._e(),
                              _vm.item.is_snack
                                ? _c("span", [_vm._v("Merienda, ")])
                                : _vm._e(),
                              _vm.item.is_dinner
                                ? _c("span", [_vm._v("Cena")])
                                : _vm._e()
                            ])
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center",
                            attrs: { cols: "12", sm: "6", md: "1" }
                          },
                          [
                            _vm.item.published
                              ? _c(
                                  "v-chip",
                                  {
                                    staticClass: "body-1",
                                    attrs: { small: "", color: "success" }
                                  },
                                  [_vm._v("Publicada")]
                                )
                              : _c("v-chip", { attrs: { small: "" } }, [
                                  _vm._v("Sin publicar")
                                ])
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center",
                            attrs: { cols: "12", sm: "6", md: "1" }
                          },
                          [
                            _c("div", { staticClass: "body-1" }, [
                              _vm._v(_vm._s(_vm.item.created))
                            ])
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-right",
                            attrs: { cols: "12", sm: "6", md: "1" }
                          },
                          _vm._l(_vm.actions, function(action, index) {
                            return _c(
                              "v-icon",
                              {
                                key: index,
                                staticClass: "mr-2 p-2",
                                attrs: { color: "gray" },
                                on: {
                                  click: function($event) {
                                    return _vm.onActionClick(
                                      action.type,
                                      _vm.item
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(action.icon) +
                                    "\n        "
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.type == "menu"
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6", md: "10" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "body-1 font-weight-bold primary--text link",
                                on: {
                                  click: function($event) {
                                    return _vm.onActionClick(
                                      _vm.titleAction.type,
                                      _vm.item
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "Semana " +
                                    _vm._s(_vm.item.week_monday) +
                                    " al " +
                                    _vm._s(_vm.item.week_sunday)
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-right",
                            attrs: { cols: "12", sm: "6", md: "2" }
                          },
                          _vm._l(_vm.actions, function(action, index) {
                            return _c(
                              "v-icon",
                              {
                                key: index,
                                staticClass: "mr-2 p-2",
                                attrs: { color: "gray" },
                                on: {
                                  click: function($event) {
                                    return _vm.onActionClick(
                                      action.type,
                                      _vm.item
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(action.icon) +
                                    "\n        "
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.type == "user"
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center",
                            attrs: { cols: "12", sm: "6", md: "3" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "body-1 font-weight-bold primary--text"
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.item.first_name) +
                                    " " +
                                    _vm._s(_vm.item.last_name)
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center",
                            attrs: { cols: "12", sm: "6", md: "1" }
                          },
                          [
                            _c("div", { staticClass: "body-1" }, [
                              _vm._v(_vm._s(_vm.item.type))
                            ])
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center",
                            attrs: { cols: "12", sm: "6", md: "1" }
                          },
                          [
                            _c("div", { staticClass: "body-1" }, [
                              _vm._v(
                                _vm._s(_vm.item.vegan ? "Vegana" : "Flexible")
                              )
                            ])
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center",
                            attrs: { cols: "12", sm: "6", md: "2" }
                          },
                          [
                            _c("div", { staticClass: "body-1" }, [
                              _vm._v(_vm._s(_vm.item.email))
                            ])
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center",
                            attrs: { cols: "12", sm: "6", md: "1" }
                          },
                          [
                            _vm.item.payment_provider === "paypal" &&
                            _vm.item.paypal_active
                              ? _c(
                                  "v-chip",
                                  {
                                    staticClass: "body-1",
                                    attrs: { small: "", color: "success" }
                                  },
                                  [_vm._v("PayPal")]
                                )
                              : _vm._e(),
                            _vm.item.payment_provider === "paypal" &&
                            !_vm.item.paypal_active
                              ? _c(
                                  "v-chip",
                                  {
                                    staticClass: "body-1",
                                    attrs: { small: "", color: "error" }
                                  },
                                  [_vm._v("PayPal")]
                                )
                              : _vm._e(),
                            _vm.item.payment_provider === "stripe"
                              ? _c(
                                  "v-chip",
                                  {
                                    staticClass: "body-1",
                                    attrs: { small: "" }
                                  },
                                  [_vm._v("Stripe")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center",
                            attrs: { cols: "12", sm: "6", md: "1" }
                          },
                          [
                            _c("div", { staticClass: "body-1" }, [
                              _vm._v(
                                _vm._s(_vm._f("dateFilter")(_vm.item.created))
                              )
                            ])
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-center",
                            attrs: { cols: "12", sm: "6", md: "1" }
                          },
                          [
                            _c("div", { staticClass: "body-1" }, [
                              _vm._v(_vm._s(_vm.item.stripe_subscription_id))
                            ])
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-right",
                            attrs: { cols: "12", sm: "6", md: "2" }
                          },
                          _vm._l(_vm.actions, function(action, index) {
                            return _c(
                              "v-icon",
                              {
                                key: index,
                                staticClass: "mr-2 p-2",
                                attrs: { color: "gray" },
                                on: {
                                  click: function($event) {
                                    return _vm.onActionClick(
                                      action.type,
                                      _vm.item
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(action.icon) +
                                    "\n        "
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }